<template>
  <div>
    <Header></Header>
    <div>
      <img class="lbt" :src="baseURL+solution.mainDrawingSrc" alt="" />
    </div>
    <div class="body">
      <div>
        <div class="cpszh">{{ solution.title }}分类</div>
        <div class="bodyTitle">
          <div class="product">{{ solution.eTitle }}</div>
<!--          <div class="more">-->
<!--            <div class="gdal">更多服务</div>-->
<!--            <div>></div>-->
<!--          </div>-->
        </div>
      </div>
      <div class="bodyTopContent">
        <div>
          <div class="jjfaFirS1" v-if="classList.length>0" >
            <div class="jjfaFirS1M"></div>
            <img class="jjfas1" :src="baseURL+classList[0].fileSrc" alt="" />
            <div class="jjfaFirS1Font1">{{ classList[0].title }}</div>
            <div class="jjfaFirS1Font2">
              {{ classList[0].introduce }}
            </div>
            <!-- <div class="jjfaFirS1Font3">了解更多</div> -->
          </div>
          <div class="jjfaFirS2" v-if="classList.length>3">
            <div class="jjfaFirS2M"></div>
            <img class="jjfas2" :src="baseURL+classList[3].fileSrc" alt="" />
            <div class="jjfaFirS2Font1">{{ classList[3].title }}</div>
            <div class="jjfaFirS2Font2">
              {{ classList[3].introduce }}
            </div>
            <!-- <div class="jjfaFirS2Font3">了解更多</div> -->
          </div>
        </div>
        <div>
          <div class="jjfaFirS3" v-if="classList.length>1">
            <div class="jjfaFirS3M"></div>
            <img class="jjfas3" :src="baseURL+classList[1].fileSrc" alt="" />
            <div class="jjfaFirS3Font1">{{ classList[1].title }}</div>
            <div class="jjfaFirS3Font2">
              {{ classList[1].introduce }}
            </div>
            <!-- <div class="jjfaFirS3Font3">了解更多</div> -->
          </div>
          <div class="jjfaFirS4" v-if="classList.length>2">
            <div class="jjfaFirS4M"></div>
            <img class="jjfas4" :src="baseURL+classList[2].fileSrc" alt="" />
            <div class="jjfaFirS4Font1">{{ classList[2].title }}</div>
            <div class="jjfaFirS4Font2">
              {{ classList[2].introduce }}
            </div>
            <!-- <div class="jjfaFirS4Font3">了解更多</div> -->
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/views/header.vue";
import Footer from "@/views/footer.vue";
import {getSolution,getSolutionClassificationPage} from "@/api/open";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      solutionId:null,
      solution:{},
      classList:[],
      baseURL: process.env.VUE_APP_BASE_API,
    };
  },
  created() {
    this.solutionId = this.$route.query.solutionId
    this.getById()
    this.getClassList()
  },
  methods: {
    getById(){
      getSolution({id:this.solutionId}).then(res=>{
        this.solution = res.data
      })
    },
    getClassList(){
      getSolutionClassificationPage({solutionId:this.solutionId}).then(res=>{
        this.classList = res.rows
      })
    }

  },
  watch: {
    '$route' (to, from) { //监听URL地址栏参数变化
      if (this.$route.query.solutionId) {
        this.solutionId = this.$route.query.solutionId
        this.getById()
        this.getClassList()
      }
    }
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
.lbt {
  width: 100%;
}
.body {
  width: 1200px;
  margin: 0 auto;
  padding-bottom: 80px;
  background: #fff !important;
}
.cpszh {
  font-size: 24px;
  padding: 40px 0 4px 32px;
  color: #183f85;
  letter-spacing: 1px;
}
.bodyTitle {
  display: flex;
  justify-content: space-between;
  margin-right: 32px;
}
.product {
  font-size: 24px;
  color: #bfbfbf;
  margin-left: 32px;
  letter-spacing: 1px;
}
.more {
  display: flex;
}
.gdal {
  margin-right: 4px;
  font-size: 14px;
  color: #7d7d7d;
}
.bodyTopContent {
  margin-top: 24px;
  display: flex;
}
.jjfas1 {
  width: 764px;
  height: 500px;
  margin: 0 24px 24px 32px;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  position: absolute;
  top: 0;
}
.jjfas2 {
  width: 764px;
  height: 350px;
  margin: 0 24px 0 32px;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  position: absolute;
  top: 0;
}
.jjfas3 {
  width: 350px;
  height: 350px;
  margin: 0 0 24px 0;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  position: absolute;
  top: 0;
}
.jjfas4 {
  width: 350px;
  height: 500px;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  position: absolute;
  top: 0;
}
.jjfaFirS1 {
  position: relative;
  transition: all 0.5s;
}
.jjfaFirS1:hover{
  transform: scale(1.05);
}
.jjfaFirS1Font1 {
  position: absolute;
  top: 24px;
  left: 56px;
  font-size: 24px;
  color: #ffffff;
  line-height: 30px;
  letter-spacing: 1px;
  z-index: 2;
}
.jjfaFirS1Font2 {
  position: absolute;
  bottom: 32px;
  left: 56px;
  width: 722px;
  color: #ffffff;
  line-height: 30px;
  letter-spacing: 1px;
  z-index: 2;
}
.jjfaFirS1Font3 {
  position: absolute;
  bottom: 40px;
  right: 40px;
  color: #ffffff;
  line-height: 17px;
  cursor: pointer;
}
.jjfaFirS2 {
  position: relative;
  transition: all 0.5s;
}
.jjfaFirS2:hover{
  transform: scale(1.05);
}
.jjfaFirS1M {
  width: 764px;
  height: 500px;
  background: linear-gradient(
    180deg,
    #000000 -150%,
    rgba(250, 250, 250, 0) 50%,
    #000000 150%
  );
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  border-radius: 20px 0px 20px 0px;
  margin: 0 24px 24px 32px;
  position: relative;
  z-index: 1;
}
.jjfaFirS2M {
  width: 764px;
  height: 353px;
  background: linear-gradient(
    180deg,
    #000000 -150%,
    rgba(250, 250, 250, 0) 50%,
    #000000 150%
  );
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  border-radius: 20px 0px 20px 0px;
  margin-left: 32px;
  position: relative;
  z-index: 1;
}
.jjfaFirS3M {
  width: 350px;
  height: 350px;
  background: linear-gradient(
    180deg,
    #000000 -150%,
    rgba(250, 250, 250, 0) 50%,
    #000000 150%
  );
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  border-radius: 20px 0px 20px 0px;
  margin: 0 0 24px 0;
  position: relative;
  z-index: 1;
}
.jjfaFirS2Font1 {
  position: absolute;
  top: 24px;
  left: 56px;
  font-size: 24px;
  color: #ffffff;
  line-height: 30px;
  letter-spacing: 1px;
  z-index: 2;
}
.jjfaFirS2Font2 {
  position: absolute;
  bottom: 32px;
  left: 56px;
  width: 722px;
  color: #ffffff;
  line-height: 30px;
  letter-spacing: 1px;
  z-index: 2;
}
.jjfaFirS2Font3 {
  position: absolute;
  bottom: 16px;
  right: 40px;
  color: #ffffff;
  line-height: 17px;
  cursor: pointer;
}
.jjfaFirS3 {
  position: relative;
  transition: all 0.5s;
}
.jjfaFirS3:hover{
  transform: scale(1.05);
}
.jjfaFirS3Font1 {
  position: absolute;
  top: 24px;
  left: 24px;
  font-size: 24px;
  color: #ffffff;
  line-height: 30px;
  letter-spacing: 1px;
  z-index: 2;
}
.jjfaFirS3Font2 {
  position: absolute;
  bottom: 32px;
  left: 24px;
  width: 309px;
  color: #ffffff;
  line-height: 30px;
  letter-spacing: 1px;
  z-index: 2;
}
.jjfaFirS3Font3 {
  position: absolute;
  bottom: 40px;
  right: 16px;
  color: #ffffff;
  line-height: 17px;
  cursor: pointer;
}
.jjfaFirS4 {
  position: relative;
  transition: all 0.5s;
}
.jjfaFirS4:hover{
  transform: scale(1.05);
}
.jjfaFirS4M {
  width: 350px;
  height: 500px;
  background: linear-gradient(
    180deg,
    #000000 -150%,
    rgba(250, 250, 250, 0) 50%,
    #000000 150%
  );
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  border-radius: 20px 0px 20px 0px;
  position: relative;
  z-index: 1;
}
.jjfaFirS4Font1 {
  position: absolute;
  top: 24px;
  left: 24px;
  font-size: 24px;
  color: #ffffff;
  line-height: 30px;
  letter-spacing: 1px;
  z-index: 2;
}
.jjfaFirS4Font2 {
  position: absolute;
  bottom: 32px;
  left: 24px;
  width: 309px;
  color: #ffffff;
  line-height: 30px;
  letter-spacing: 1px;
  z-index: 2;
}
.jjfaFirS4Font3 {
  position: absolute;
  bottom: 16px;
  right: 16px;
  color: #ffffff;
  line-height: 17px;
  cursor: pointer;
}
</style>
